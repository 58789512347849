.block {
    display: block !important;
  }
  
  .flex {
    display: flex !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-55 {
    width: 55% !important;
  }
  
  .w-45 {
    width: 45% !important;
  }
  
  .w-60 {
    width: 60% !important;
  }
  
  .w-40 {
    width: 40% !important;
  }
  
  .w-48 {
    width: 48% !important;
  }
  
  .w-17 {
    width: 17% !important;
  }
  
  .w-18 {
    width: 18% !important;
  }
  
  .row {
    position: relative !important;
    border-bottom: 1px solid $color-gray !important;
  }
  
  .row__remove {
    display: flex !important;
    padding: 0 !important;
    position: absolute !important;
    align-items: flex-end !important;
    opacity: 0 !important;
    transition: opacity 0.2s ease-in-out !important;
  
    .row:hover &,
    .row:focus-within & {
      opacity: 1 !important;
    }
  }