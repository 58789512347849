.dark {
    color: $color-dark;
  }
  
  .white {
    color: $color-white;
  }
  
  .bg-white {
    background-color: $color-white;
  }
  
  .bg-dark {
    background-color: $color-dark-2;
  }
  
  .bg-gray {
    background-color: $color-gray;
  }
  
  .bg-red {
    background-color: $color-red;
  }
  
  .bg-green {
    background-color: $color-green;
  }